<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      
      <b-card-title>Últimos Hits</b-card-title>
        
      <b-card-text class="font-small-2">
          <b-card-text class="font-small-2">
            <b-button
              class="font-small-2" 
              variant="outline-primary" 
              size="sm"
              :to="{ name: 'hit-lista'}"
            >
              Ver mais
            </b-button>
          </b-card-text>
        </b-card-text>

    </b-card-header>

    <!-- body -->
    <b-card-body>

      <div 
        v-if="!hits.length"
        class="d-flex justify-content-center align-items-center text-center pt-2 pb-2" 
        style="height: 100%;"
      >
        <div>
          <feather-icon
            size="30"
            icon="MusicIcon"
          />
          <h5 class="mt-1">Você ainda não enviou nenhum hit</h5>
          <small>Os seus hits mais recentes serão exibidos aqui</small>
        </div>
      </div>

      <div
        v-for="hit in hits"
        :key="hit.id"
        class="browser-states"
      >
      
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar
              rounded
              size="42"
              :variant="`light-${statusAprovacaoVariant(hit.statusAprovacaoHit)}`"
            >
              <feather-icon
                size="18"
                icon="MusicIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ hit.nomeComposicao }}
            </h6>
            <small>
              <b-badge
                pill
                :variant="`light-${statusAprovacaoVariant(hit.statusAprovacaoHit)}`"
                class="text-capitalize badgeStatus"
              >
                {{ statusAprovacaoLabel(hit.statusAprovacaoHit) }}
              </b-badge>  
            </small>
          </b-media-body>
        </b-media>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BBadge, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BImg, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BCard,
    BButton,
    BAvatar,
    BBadge,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    VueApexCharts,
  },
  data() {
    return {
      hits: [],

      page: 0,
      size: 5,
      sort: 'DESC',
    }
  },
  created() {
    this.hitsLista();
  },
  methods: {

    // STATUS

    statusAprovacaoVariant(status) {
      if (status === 'EM_ANALISE') return 'warning'
      if (status === 'APROVADO') return 'success'
      if (status === 'REPROVADO_GERAL') return 'danger'
      if (status === 'REPROVADO_INFORMAÇÕES') return 'danger'
      if (status === 'REPROVADO_GUIA') return 'danger'
      if (status === 'PENDENTE_PAGAMENTO') return 'warning'
      if (status === 'AGUARDANDO_GUIA') return 'warning'
      return 'warning'
    },

    statusAprovacaoLabel(status) {
      if (status === 'EM_ANALISE') return 'Em análise'
      if (status === 'APROVADO') return 'Aprovado'
      if (status === 'REPROVADO_GERAL') return 'Reprovado'
      if (status === 'REPROVADO_INFORMACOES') return 'Reprovado'
      if (status === 'REPROVADO_GUIA') return 'Reprovado'
      if (status === 'PENDENTE_PAGAMENTO') return 'Pendente Pagamento'
      if (status === 'AGUARDANDO_GUIA') return 'Aguardando Guia'
      return 'Em análise'
    },

    // STATUS

    
    hitsLista() {

      useJwt.hitCompositorLista({
        page: this.page,
        size: this.size,
        sort: this.sort,
      })
        .then(response => {

          this.hits = response.data.content
          //console.log(response.data.content)

        })
        .catch(error => {
        // this.hits = '';
          console.log(error)
        })
        .finally(() => {
          //this.carregamentoApi = false
        })
    },

  }
}
</script>
