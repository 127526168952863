<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Painel de Hits</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        
        <b-col
          xl="4"
          sm="6"
          class="mb-2 mb-xl-0"
        >
          <b-link :to="'/hit/lista'">
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-primary"
                >
                  <feather-icon
                    size="24"
                    icon="MusicIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ hitsEnviados }}
                </h4>
                <b-card-text class="font-small-3 mb-0 text-dark">
                  Enviados
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-link>
        </b-col>

        <b-col
          xl="4"
          sm="6"
          class="mb-2 mb-sm-0"
        >
          <b-link :to="'/negociacoes/vendidas'">
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-success"
                >
                  <feather-icon
                    size="24"
                    icon="CheckCircleIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ hitsVendidos }}
                </h4>
                <b-card-text class="font-small-3 mb-0 text-dark">
                  Vendidos
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-link>
        </b-col>

        <b-col
          xl="4"
          sm="6"
        >
          <b-link :to="'/negociacoes'">
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-warning"
                >
                  <feather-icon
                    size="24"
                    icon="MessageSquareIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ hitsNegociacoes }}
                </h4>
                <b-card-text class="font-small-3 mb-0 text-dark">
                  Negociações
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-link>
        </b-col>


      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BLink, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      hitsEnviados: 0,
      hitsVendidos: 0,
      hitsNegociacoes: 0,
    }
  },
  created() {
    this.dashboardCompositorTotalizador();
  },
  methods: {


    dashboardCompositorTotalizador() {

      useJwt.dashboardCompositorTotalizador()
        .then(response => {


          this.hitsEnviados = response.data.enviado
          this.hitsVendidos = response.data.vendido
          this.hitsNegociacoes = response.data.negociacao


        })
        .catch(error => {
        // this.hits = '';
          console.log(error)
        })
        .finally(() => {
          //this.carregamentoApi = false
        })
    },

  }
}
</script>
