<template>
  <section id="dashboard-compositor">
    <b-row class="match-height">
      <b-col
        xl="6"
        md="6"
      >
        <compositor-saldo />
      </b-col>
      <b-col
        xl="6"
        md="6"
      >
        <compositor-totalizador />
      </b-col>
       <!-- Bar Chart - Orders -->
       <!-- <b-col
          xl="2"
          cols="6"
        >
          <compositor-plays />
        </b-col> -->
        <!--/ Bar Chart - Orders -->
    </b-row>

    <b-row class="match-height">

      <!-- Transaction Card -->
      <b-col
        lg="6"
        md="6"
      >
        <compositor-ultimas-negociacoes />
      </b-col>
      <!--/ Transaction Card -->

      <!-- Browser States Card -->
      <b-col
        lg="6"
        md="6"
      >
        <compositor-ultimos-hits />
      </b-col>
      <!--/ Browser States Card -->
    </b-row>

    <b-row>
      <b-col
        lg="6"
        md="6"
      >
        <compositor-campanhas-recentes />
      </b-col>

      <b-col
        lg="6"
        md="6"
      >
        <compositor-campanhas-proximas />
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import CompositorSaldo from './CompositorSaldo.vue'
import CompositorTotalizador from './CompositorTotalizador.vue'
import CompositorPlays from './CompositorPlays.vue'
import CompositorUltimosHits from './CompositorUltimosHits.vue'
import CompositorCampanhasRecentes from './CompositorCampanhasRecentes.vue'
import CompositorCampanhasProximas from './CompositorCampanhasProximas.vue'
import CompositorUltimasNegociacoes from './CompositorUltimasNegociacoes.vue'

export default {
  components: {
    BRow,
    BCol,

    CompositorSaldo,
    CompositorTotalizador,
    CompositorPlays,
    CompositorUltimosHits,
    CompositorCampanhasRecentes,
    CompositorCampanhasProximas,
    CompositorUltimasNegociacoes,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
