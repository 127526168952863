<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>

      <b-card-title>Negociações Recentes</b-card-title>
        
      <b-card-text class="font-small-2">
          <b-card-text class="font-small-2">
            <b-button
              class="font-small-2" 
              variant="outline-primary" 
              size="sm"
              :to="{ name: 'negociacoes'}"
            >
              Ver mais
            </b-button>
          </b-card-text>
        </b-card-text>

    </b-card-header>

    <b-card-body>

      <div 
        v-if="!negociacoes.length"
        class="d-flex justify-content-center align-items-center text-center pt-2 pb-2" 
        style="height: 100%;"
      >
        <div>
          <feather-icon
            size="30"
            icon="GridIcon"
          />
          <h5 class="mt-1">Não há negociações no momento</h5>
          <small>As negociações mais recentes serão exibidas aqui</small>
        </div>
      </div>

      <div
        v-for="negociacao in negociacoes"
        :key="negociacao.id"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="negociacaoStatusCor(negociacao.statusNegociacaoValor)"
            >
              <feather-icon
                size="18"
                :icon="negociacaoStatusIcone(negociacao.statusNegociacaoValor)"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small class="text-dark">#{{ negociacao.id }}</small>
            <h6 class="transaction-title">
              {{ negociacao.hitNomeComposicao }}
            </h6>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
        >
        <b-button
          size="sm"
          variant="primary"
          :to="{ name: 'negociacao-visualizar', params: { id: negociacao.id } }"
        >
          <feather-icon
            icon="EyeIcon"
          />
        </b-button>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BCardHeader, BCardTitle, BCardText, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem, BImg,
} from 'bootstrap-vue'

import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BButton,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      negociacoes: {},
    }    
  },
  created() {
    this.negociacoesLista()
  },
  methods: {

    negociacaoStatusCor(status) {
      if (status == '0') return 'light-secondary'
      if (status == '1' || status == '2') return 'light-success'
      if (status == '3' || status == '4') return 'light-danger'
      return 'light-secondary'
    },

    negociacaoStatusIcone(status) {
      if (status == '0') return 'MessageSquareIcon'
      if (status == '1' || status == '2') return 'CheckIcon'
      if (status == '3' || status == '4') return 'DeleteIcon'
      return 'MessageSquareIcon'
    },

    negociacoesLista() {
      this.carregamentoApi = true

      useJwt.propostasLista({
        page: 0,
        size: 5,
        sort: 'DESC',
      })
        .then(response => {
          // console.log( response.data );

          this.negociacoes = response.data.content
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },

  }
}
</script>
