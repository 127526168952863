<template>
  <b-card
    text-variant="center"
    class="card card-congratulations"
  >

    <!-- images -->
    <b-img
      :src="require('@/assets/images/elements/decore-left.png')"
      class="congratulations-img-left"
    />
    <b-img
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
    />
    <!--/ images -->

    <b-avatar
      variant="primary"
      size="50"
      class="shadow mb-2"
    >
      <feather-icon
        size="15"
        icon="MusicIcon"
      />
    </b-avatar>
    <h2 class="mt-100 text-white">
      Olá, {{ nome }}
    </h2>
    <b-card-text class="m-auto w-75 text-dark">
      <small>Chegou a sua hora de enviar seu <b>próximo hit!</b></small>
    </b-card-text>

    <h4 class="mb-75 pt-50">
      <span class="text-white"><small>Saldo:</small> {{ saldo }} </span>
      <b-icon
        :class="{ rotating: carregandoSaldo }"        
        icon="arrow-clockwise"
        font-size="18"
        cursor="pointer"
        class="text-white"
        @click="carregarSaldo()"
      />
    </h4>
    <b-button
      v-if="saldo != 0"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="success"
      size="sm"
      :to="{ name: 'hit-cadastrar'}"
    >
      Enviar Hit
    </b-button>
    <b-button
      v-if="saldo == 0"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="success"
      size="sm"
      :to="{ name: 'financeiro-assinatura-planos'}"
    >
      Inserir Saldo
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BLink, BButton, BImg, IconsPlugin, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

import { getUserData } from '@/auth/utils'

import useJwt from '@/auth/jwt/useJwt'


export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
    IconsPlugin,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      nome: null,
      saldo: 0,
      carregandoSaldo: false,
    }
  },
  created() {
    this.nomeSaldo();
  },
  methods: {
    
    nomeSaldo() {

      const userData = getUserData()
      this.nome = userData.name.split(' ')[0] || userData.email
      this.carregarSaldo();

    },

    carregarSaldo() {
      
      this.carregandoSaldo = true

      useJwt.dashboardCompositorCarregarSaldo()
      .then(response => {

        this.saldo = response.data

      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {

        setTimeout(() => {
          this.carregandoSaldo = false
        }, 1000)

      })

    }
    
  },
}
</script>

<style>

@-webkit-keyframes rotating {
    from{
        -webkit-transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 1s linear infinite;
}

</style>
